import request from '@/utils/request'
// import store from '@/store'
import Vuetify from '@/plugins/vuetify'
var langId = Vuetify.preset.lang.current

export function fetchSubscriptionVoucher (paramsQuery) {
  return request({
    url: langId + '/subscriptionVoucher',
    method: 'get',
    params: paramsQuery
  })
}

export function createVoucher (data) {
  return request({
    url: langId + '/subscriptionVoucher',
    method: 'post',
    data,
    headers: { 'content-type': 'multipart/form-data' }
  })
}

export function exportFile () {
  return request({
    url: langId + '/subscriptionVoucher/export',
    method: 'get',
    responseType: 'blob'
  })
}

export function detailVoucher (paramsQuery) {
  return request({
    url: langId + '/subscriptionVoucher/' + paramsQuery,
    method: 'get'
  })
}

export function updateVoucher (data, id) {
  return request({
    url: langId + '/subscriptionVoucher/update/' + id,
    method: 'post',
    data,
    headers: { 'content-type': 'multipart/form-data' }
  })
}

export function deleteVoucher (paramsQuery) {
  return request({
    url: langId + '/subscriptionVoucher/' + paramsQuery,
    method: 'delete'
  })
}

export function listCompany (paramsQuery) {
  return request({
    url: langId + '/company',
    method: 'get',
    params: paramsQuery
  })
}
