<template>
  <div>
    <v-dialog
      v-model="showDialog"
      persistent
      :max-width="widthDialog">
      <v-card class="pb-5">
        <v-toolbar color="elevation-0">
          <v-toolbar-title >
            <v-icon
            v-if="viewScene_3"
              @click="handleBack">
              mdi-arrow-left
            </v-icon>
            <span class="ml-4 font-weight-bold">
              Select Business
            </span>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              @click="closeBusiness">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col v-if="viewScene_1" cols="4">
              <v-list dense>
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                >
                  <v-btn
                    text
                    style="width: 200px;"
                    class="text-capitalize"
                    @click="handleSelectView(item.id)"
                  >
                    {{ item.name }}
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-col>
            <!-- <v-divider class="hidden-sm-only hidden-xs-only mx-6" :vertical="true" /> -->
            <v-col v-if="viewScene_2" cols="8" class="border-left border-dashed">
              <div class="mb-3">
                <span>
                  Selcted Business {{ '(' + dataAddBusiness.length + ')' }}
                </span>
                <!-- <v-spacer></v-spacer> -->
                <span v-if="dataAddBusiness.length > 0" class="float-right">
                  <v-btn
                    outlined
                    small
                    class="text-capitalize mr-1"
                    @click="handleSelectToEdit">
                    Select
                  </v-btn>
                  <v-btn
                    small
                    outlined
                    color="#00D79E"
                    class="text-capitalize"
                    @click="handleAddBusiness">
                    Add Business
                  </v-btn>
                </span>
              </div>
              <div v-if="dataAddBusiness.length <= 0">
                <v-btn
                  outlined
                  block
                  small
                  color="#00D79E"
                  class="text-capitalize"
                  @click="handleAddBusiness">
                  + Add Business
                </v-btn>
              </div>
              <div v-if="dataAddBusiness.length > 0">
                <v-skeleton-loader
                  :loading="listLoading"
                  :transition="transition"
                  type="table"
                >
                  <v-data-table
                    :headers="headers"
                    :items="dataAddBusiness"
                    :items-per-page="15"
                    :hide-default-header="true"
                    :hide-default-footer="true"
                    class="mytable"
                    style="border-bottom: none"
                    @click:row="handleSelect"
                  >
                  </v-data-table>
                </v-skeleton-loader>
              </div>
            </v-col>
            <v-col v-if="viewScene_3" cols="12">
              <div class="d-flex mx-4">
                <v-text-field
                  outlined
                  dense
                  placeholder="Search Merchant"
                  class="mr-1"
                  @change="handleSearchMerchant"
                />
                <v-select
                  :items="dataBusinessType"
                  :clearable="true"
                  item-text="name"
                  item-value="id"
                  solo
                  dense
                  label="All Business Type"
                  style="width: 45px;"
                  class="mr-1"
                  @change="handleFilterBusinessType"
                />
                <v-select
                  :items="dataListCity"
                  :clearable="true"
                  item-text="name"
                  item-value="id"
                  solo
                  dense
                  label="All City"
                  style="width: 45px;"
                  class="mr-1"
                  @change="handleFilterCity"
                />
              </div>
              <div id="custom-scroll" style="height: 300px; overflow-y: scroll;">
                <v-skeleton-loader
                  :loading="listLoading"
                  :transition="transition"
                  type="table"
                >
                  <v-data-table
                    v-model="dataSelected"
                    :headers="headers"
                    :items="dataListCompany"
                    :items-per-page="15"
                    :disable-sort="true"
                    hide-default-footer
                    show-select
                    class="mytable p-0"
                    style="border-bottom: none"
                    @click:row="handleSelect"
                  >
                    <template v-slot:header.data-table-select="{ on, props }" class="d-flex">
                      <v-simple-checkbox
                        color="#00D79E"
                        v-bind="props"
                        v-on="on"
                        :ripple="false"
                      ></v-simple-checkbox>
                    </template>
                  </v-data-table>
                </v-skeleton-loader>
              </div>
            </v-col>
            <v-col v-if="viewScene_4" cols="12">
              <div>
                <v-skeleton-loader
                  :loading="listLoading"
                  :transition="transition"
                  type="table"
                >
                  <v-data-table
                    v-model="dataSelectedToEdit"
                    :headers="headersEditListBusines"
                    :items="dataEditListBusiness"
                    :items-per-page="15"
                    item-key="id"
                    hide-default-footer
                    :disable-sort="true"
                    show-select
                    class="mytable"
                    style="border-bottom: none"
                    @click:row="handleSelect"
                  >
                    <!-- <template
                      v-if="dataSelectedToEdit.length > 0"
                      v-slot:header.business_type_name="{ header }">
                    </template> -->
                    <template v-slot:header.city_name>
                      <v-btn
                      v-if="dataSelectedToEdit.length > 0"
                      outlined
                      small
                      color="#F5295A"
                      class="text-capitalize mr-1"
                      @click="handleRemoveList">
                        Remove Selcted
                      </v-btn>
                      <v-btn
                      outlined
                      small
                      class="text-capitalize"
                      @click="handleCancleEdit">
                        Cancel
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
          <div
            v-if="viewScene_3"
            class="text-right"
            style="width: 100%">
            <!-- <v-spacer></v-spacer> -->
            <v-btn
              depressed
              color="#00D79E"
              class="text-capitalize white--text"
              @click="handleAddSelectBusiness">
              Add Merchant
            </v-btn>
          </div>
          <div
            v-if="!viewScene_3 && !viewScene_4"
            class="text-right"
            style="width: 100%">
            <!-- <v-spacer></v-spacer> -->
            <v-btn
              depressed
              color="#00D79E"
              class="text-capitalize white--text"
              @click="handleNextToPreview">
              Next
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <voucher-preview
      :showDialog="dialogVoucherPreview"
      :dataVoucher="dataVoucher"
      @vRefreshData="handleDeleteRefreshData"
      @close="dialogVoucherPreview = false"
    />
  </div>
</template>

<script>
import { listCity, businesstype } from '@/api/global'
import { listCompany } from '@/api/zenwel-biz/bizdeals'
import VoucherPreview from './voucher-preview'
export default {
  components: {
    VoucherPreview
  },

  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    dataVoucher: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      item: {},
      items: [
        { id: 0, name: 'All Business' },
        { id: 1, name: 'New Business Only' },
        { id: 2, name: 'Custom Business' }
      ],
      dataBusiness: {},
      viewScene_1: true,
      viewScene_2: false,
      viewScene_3: false,
      viewScene_4: false,
      listLoading: false,
      transition: 'scale-transition',
      headers: [
        {
          text: 'Select All',
          align: 'left',
          value: 'name'
        },
        {
          align: 'left',
          value: 'business_type_name'
        },
        {
          align: 'left',
          value: 'city_name'
        }
      ],
      headersEditListBusines: [
        {
          text: 'Select All',
          align: 'left',
          value: 'name'
        },
        {
          align: 'left',
          value: 'business_type_name'
        },
        {
          align: 'left',
          value: 'city_name'
        }
      ],
      data: [
        { id: 1, name: 'All Business' },
        { id: 2, name: 'New Business Only' },
        { id: 3, name: 'Custom Business' }
      ],
      dataSelected: [],
      dataAddBusiness: [],
      viewEditBusiness: false,
      dataListCompany: [],
      dataEditListBusiness: [],
      dataSelectedToEdit: [],
      dialogVoucherPreview: false,
      dataListCity: [],
      paramsQuery: {
        city_id: ''
      },
      dataBusinessType: []
    }
  },

  computed: {
    // widthDialog () {
    //   switch (this.$vuetify.breakpoint.name) {
    //     case this.viewScene_1 === true : return '30%'
    //     case (this.viewScene_1 === true && this.viewScene_2 === true) : return '70%'
    //     case this.viewScene_3 : return '70%'
    //     case this.viewScene_4: return '70%'
    //     default: return '30%'
    //   }
    // }
    widthDialog () {
      if (this.viewScene_1 === true && this.viewScene_2 === false) {
        return '30%'
      } else if (this.viewScene_2 === true) {
        return '70%'
      } else if (this.viewScene_3 === true) {
        return '70%'
      } else {
        return '70%'
      }
    }
  },

  watch: {
    showDialog: {
      immediate: true,
      handler (payload) {
        if (payload) {
          this.getData()
        }
      }
    }
  },

  methods: {
    getData () {
      listCompany(this.paramsQuery).then((response) => {
        this.dataListCompany = response.data.data
      }).catch((error) => {
        console.log(error)
        this.dataListCompany = []
      })
      listCity().then((respone) => {
        this.dataListCity = respone.data.data
      }).catch((error) => {
        console.log(error)
      })
      businesstype().then((response) => {
        this.dataBusinessType = response.data.data
      }).catch((error) => {
        console.log(error)
      })
      // this.dataAddBusiness = [{}]
      if (this.dataVoucher.id) {
        this.dataAddBusiness = this.dataVoucher.list_business.map((item, key) => {
          const business = {}
          business.id = item.com_id
          business.name = item.com_name
          business.city_name = item.city_name
          return business
        })
        this.dataSelected = this.dataVoucher.list_business.map((item, key) => {
          const business = {}
          business.id = item.com_id
          // business.name = item.com_name
          // business.city_name = item.city_name
          return business
        })
        // this.dataAddBusiness = this.dataVoucher.list_business
      }
    },
    closeBusiness () {
      this.$emit('closeBusiness')
      this.viewScene_1 = true
      this.viewScene_2 = false
      this.viewScene_3 = false
      this.viewScene_4 = false
    },
    handleFilterBusinessType () {
      console.log('handleFilterBusinessType')
    },
    handleFilterCity (e) {
      this.paramsQuery.city_id = e
      this.getData()
    },
    handleSelect () {
      console.log('handleSelect', this.dataSelected)
    },
    handleSelectView (value) {
      this.dataVoucher.new_account = value
      if (value === 2) {
        this.viewScene_2 = true
      }
    },
    handleAddBusiness () {
      this.viewScene_1 = false
      this.viewScene_2 = false
      this.viewScene_3 = true
    },
    handleAddSelectBusiness () {
      this.dataAddBusiness = this.dataSelected
      this.viewScene_3 = false
      this.viewScene_1 = true
      this.viewScene_2 = true
    },
    handleSelectToEdit () {
      this.dataEditListBusiness = this.dataAddBusiness
      this.viewScene_4 = true
      this.viewScene_1 = false
      this.viewScene_2 = false
    },
    handleRemoveList () {
      this.dataEditListBusiness.splice(this.dataSelectedToEdit, this.dataSelectedToEdit.length)
    },
    handleNextToPreview () {
      if (this.dataVoucher.id) {
        var data = {
          new_account: this.dataVoucher.new_account,
          com_id: []
        }
        this.dataAddBusiness.map((item) => {
          data.com_id.push(item.id)
        })
        this.$emit('editBusiness', data)
        this.$emit('closeBusiness')
      } else {
        // this.dataVoucher.com_id = { ...this.dataAddBusiness }
        this.dataVoucher.com_id = []
        this.dataAddBusiness.map((item) => {
          this.dataVoucher.com_id.push(item.id)
        })
        this.$emit('closeBusiness')
        this.dialogVoucherPreview = true
      }
    },
    handleSearchMerchant (e) {
      this.paramsQuery = {
        search_column: 'name',
        search_text: e
      }
      this.getData()
    },
    handleCancleEdit () {
      this.viewScene_1 = true
      this.viewScene_2 = true
      this.viewScene_3 = false
      this.viewScene_4 = false
    },
    handleDeleteRefreshData () {
      this.$emit('vRefreshData')
    },
    handleBack () {
      this.viewScene_1 = true
      this.viewScene_2 = true
      this.viewScene_3 = false
      this.viewScene_4 = false
    }
  }
}
</script>
