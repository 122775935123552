<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      width="400"
    >
      <v-card>
        <v-card-title class="headline">
          Delete Confirmation
        </v-card-title>
        <v-card-text>
          <div class="text-center font-weight-black">
          {{ 'You are about to delete ' + data.title + ' voucher. Are you sure?'}}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="text-capitalize"
            @click="closeDialog"
          >
            Back
          </v-btn>
          <v-btn
            depressed
            color="#F5295A"
            class="white--text text-capitalize"
            @click="handleDelete"
          >
            Yes, remove voucher
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { deleteVoucher } from '@/api/zenwel-biz/bizdeals'
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },

  methods: {
    closeDialog () {
      this.$emit('colseDeleteConfirmation')
    },
    handleDelete () {
      console.log('Data id', this.data.id)
      deleteVoucher(this.data.id).then((response) => {
        this.$emit('dRefreshData')
        console.log('Sucess', response)
      }).catch((error) => {
        console.log('Error', error)
      })
      this.$emit('colseDeleteConfirmation')
    }
  }
}
</script>
