<template>
  <div>
    <v-dialog
      v-model="showDialog"
      persistent
      width="400">
      <v-card class="pb-5">
        <v-toolbar color="elevation-0">
          <v-toolbar-title>
            <span class="ml-3 font-weight-bold">
              Voucher Preview
            </span>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="mx-6">
          <v-row>
            <v-col cols="12">
              <div class="d-flex flex-no-wrap">
                <span>Select Image</span>
              </div>
              <div class="d-flex justify-space-between">
                <div>
                  <v-btn
                    v-for="(item, key) in formView"
                    :key="key"
                    :active-class="item"
                    rounded
                    outlined
                    small
                    color="#00D79E"
                    class="mr-1 text-capitalize"
                    @click="handleFormView(item)">
                    {{ item }}
                  </v-btn>
                </div>
                <div class="justify-end">
                  <v-btn
                    v-if="activeView !== 'Default' && url !== ''"
                    rounded
                    outlined
                    small
                    class="text-capitalize"
                    @click="onChangeClick">
                    Change Picture
                  </v-btn>
                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept="image/*"
                    @change="onFileChanged"
                  >
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <v-card
                v-if="activeView === 'Default'"
                :color="voucherBox"
                outlined
                height="200"
                width="400"
                class="mx-auto">
                <div
                  class="text-center ma-10"
                  style="position: absolute; z-index: 1">
                  <h2 class="white--text">
                    {{ dataVoucher.name }}
                  </h2>
                </div>
                  <v-img
                    v-model="gambar"
                    height="200"
                    width="400"
                    :src="require('@/assets/'+voucherBox+'.png')"
                    @load="handleLoadImage"
                  />
              </v-card>
              <v-card
                v-else
                outlined
                height="200"
                width="400"
                class="text-center mx-auto">
                <v-img
                  v-if="url !== ''"
                  :src="url"
                  max-height="200"
                  max-width="300"
                  class="absolute-img"
                  style="z-index: 1">
                </v-img>
                <div
                  v-if="activeView !== 'Default'"
                  class="mt-10">
                  <v-btn
                    :loading="isSelecting"
                    text
                    rounded
                    depressed
                    @click="onButtonClick">
                    <v-icon>
                      mdi-image
                    </v-icon>
                  </v-btn>
                  <div class="black--text">
                    Upload Image
                  </div>
                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept="image/*"
                    @change="onFileChanged"
                  >
                </div>
              </v-card>
            </v-col>
            <v-col cols="12">
              <div class="d-flex flex-no-wrap justify-space-between">
                <span>Select Color</span>
              </div>
              <v-btn
                v-for="(item, key) in pickerData"
                :key="key"
                depressed
                small
                :color="item.id"
                class="mr-3 mt-2"
                @click="handleColorPicker(item.name)"
              />
            </v-col>
          </v-row>
          <div
            class="text-right mt-9"
            style="width: 100%">
            <v-btn
              depressed
              color="#00D79E"
              class="text-capitalize white--text"
              small
              @click="habdleCreateVoucher">
              Create Voucher
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <success-dialog
      :showDialog="dialogSuccess"
      :data="vID"
      @sRefreshData="handleSuccessRefreshData"
      @closeSuccess="dialogSuccess = false"
    />
    <loading
      :showDialog="loadingView"
      @closeLoading="loadingView = false"
    />
  </div>
</template>

<script>
import { createVoucher } from '@/api/zenwel-biz/bizdeals'
import SuccessDialog from './success.vue'
import Loading from './loading.vue'
export default {
  components: {
    SuccessDialog,
    Loading
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    dataVoucher: {
      type: Object,
      default: null
    },
    vID: {
      type: String
    }
  },

  data () {
    return {
      pickerData: [
        { id: '#00D79E', name: '00D79E' },
        { id: '#F59700', name: 'F59700' },
        { id: '#F5295A', name: 'F5295A' },
        { id: '#2882F5', name: '2882F5' },
        { id: '#63B16D', name: '63B16D' }
      ],
      voucherBox: '00D79E',
      formView: ['Default', 'Upload'],
      activeView: 'Default',
      image: {},
      url: '',
      defaultButtonText: 'x',
      selectedFile: null,
      isSelecting: false,
      imageOptions: [
        { id: 1, name: 'Change Picture' },
        { id: 2, name: 'Delete Picture' }
      ],
      dialogSuccess: false,
      loadingView: false,
      gambar: {}
    }
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    },
    buttonText () {
      return this.selectedFile ? this.selectedFile.name : this.defaultButtonText
    }
  },

  watch: {
    showDialog: {
      immediate: true,
      handler (payload) {
        console.log('Data watcher', payload, this.vID)
        if (payload && this.vID) {
          this.getData()
        }
      }
    }
  },

  methods: {
    getData () {
      this.url = this.dataVoucher.photo
    },
    closeDialog () {
      this.$emit('close', false)
      // this.$router.push('/zenwel-biz/biz-deals')
    },
    handleColorPicker (value) {
      this.voucherBox = value
    },
    handleFormView (value) {
      this.activeView = value
      console.log('Value', value)
    },
    onButtonClick () {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },
    onChangeClick () {
      // this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },
    onFileChanged (e) {
      this.selectedFile = e.target.files[0]
      this.url = URL.createObjectURL(this.selectedFile)
      console.log('This is my file', this.selectedFile)
      console.log('This ', this.vID)
    },
    handleImageOption (e) {
      console.log('eee', e)
      if (e === 1) {
        this.$refs.uploader.click()
      } else {
        this.url = ''
      }
    },
    habdleCreateVoucher () {
      // this.$emit('close')
      // this.dialogSuccess = true
      const data = new FormData()
      data.append('code', this.dataVoucher.code)
      data.append('com_id', this.dataVoucher.com_id)
      data.append('country_id', this.dataVoucher.country_id)
      data.append('currency_id', this.dataVoucher.currency_id)
      data.append('discount_type', this.dataVoucher.discount_type) // ?
      data.append('discount_value', this.dataVoucher.discount_value)
      data.append('end_date', this.dataVoucher.end_date)
      data.append('name', this.dataVoucher.name)
      data.append('new_account', this.dataVoucher.new_account)
      data.append('origin_subscription_id', this.dataVoucher.origin_subscription_id)
      data.append('redeemable_all_com', this.dataVoucher.redeemable_all_com)
      data.append('redeemable_per_com', this.dataVoucher.redeemable_per_com)
      data.append('subscription_id', this.dataVoucher.subscription_id)
      data.append('use_end_date', this.dataVoucher.use_end_date)
      data.append('use_origin_subscription_id', this.dataVoucher.use_origin_subscription_id)
      data.append('use_redeemable_all_com', this.dataVoucher.use_redeemable_all_com)
      data.append('use_redeemable_per_com', this.dataVoucher.use_redeemable_per_com)
      data.append('use_subscription_id', this.dataVoucher.use_subscription_id)
      data.append('use_country_id', this.dataVoucher.use_country_id)
      data.append('voucher_type', this.dataVoucher.voucher_type)
      data.append('color', this.voucherBox)
      data.append('photo', this.selectedFile, 'dff')
      console.log('Riski handoko data', this.dataVoucher)
      console.log('Data photo', data)
      var datax = {
        ...this.dataVoucher,
        ...data
      }
      console.log('Data photoxx', datax)
      createVoucher(data).then((response) => {
        this.$emit('close')
        this.loadingView = true
        setTimeout(() => (this.dialogSuccess = true), 500)
        this.dialogSuccess = true
        console.log('Success')
      }).catch((error) => {
        console.log('heloo', error)
      })
    },
    handleLoadImage (e) {
      var uri = new Image()
      uri.src = e
      console.log('URI', uri.src)
      fetch(uri.src).then((res) =>
        res.blob()
      ).then(blob => {
        this.selectedFile = blob
        console.log('Data selected', this.selectedFile)
      })
    },
    handleSuccessRefreshData () {
      this.$emit('vRefreshData')
    }
  }
}
</script>
