<template>
    <div>
        <v-card flat>
          <div>
            <v-container
              class="white lighten-5 fill-height"
              style="width: 100%;">
              <div style="width: 100%;">
                  <v-card
                    class="pa-2"
                    width="100%">
                    <v-card-title class="justify-space-between">
                      <span class="mr-1" style="color: #6A6A6A">
                        <v-select
                          :items="allTypes"
                          :clearable="true"
                          label="All Types"
                          style="width: 121px;"
                          @change="handleFilterType"
                        />
                      </span>
                      <span style="color: #6A6A6A">
                        <v-select
                          :items="allPackages"
                          :clearable="true"
                          label="All Packages"
                          item-text="name"
                          item-value="id"
                          style="width: 121px;"
                          @change="handleFilterPackage"
                        />
                      </span>
                      <v-spacer />
                      <v-btn
                        rounded
                        class="text-capitalize"
                        style="margin-right: 8px"
                        color="#00D79E"
                        @click="handleAddVoucher">
                        {{ '+ Add Voucher' }}
                      </v-btn>
                      <v-btn
                        rounded
                        outlined
                        class="text-capitalize"
                        style="margin-right: 8px"
                        @click="handleExport">
                        <v-icon left>$vuetify.icons.download</v-icon>
                        {{ lang.t('$vuetify.download') }}
                      </v-btn>
                      <v-btn
                        rounded
                        outlined
                        class="text-capitalize"
                        style="margin-right: 8px">
                        <v-icon left>$vuetify.icons.print</v-icon>
                        {{ lang.t('$vuetify.print') }}
                      </v-btn>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        style="width: 10px;"
                        @change="handleSearch"
                      />
                    </v-card-title>
                    <v-skeleton-loader
                      :loading="listLoading"
                      :transition="transition"
                      type="table"
                    >
                      <v-data-table
                        :headers="headers"
                        :items="data"
                        :search="search"
                        :items-per-page="15"
                        hide-default-footer
                        class="mytable"
                        style="border-bottom: none"
                        @click:row="handleEdit"
                      >
                        <template>
                          <span style="color: #00B082">
                            Voucher Code
                          </span>
                        </template>
                      </v-data-table>
                    </v-skeleton-loader>
                  </v-card>
              </div>
              <div
                class="align-center"
                style="width: 100%">
                  <v-spacer></v-spacer><br>
                  <v-pagination
                  v-model="page"
                  color="#00B082"
                  :length="total_page"
                  :total-visible="7"
                  circle
                  @input="changePage"
                  ></v-pagination>
              </div>
            </v-container>
          </div>
        </v-card>
        <dialog-create
          :showDialog="showDialogCreate"
          :vID="voucherID"
          @refreshData="getData"
          @close="showDialogCreate = false"
        />
        <dialog-edit
          :showDialog="showDialogEdit"
          :vID="voucherID"
          @refreshData="getData"
          @close="showDialogEdit = false"
        />
    </div>
</template>

<script>
import { fetchSubscriptionVoucher, exportFile } from '@/api/zenwel-biz/bizdeals'
import DialogCreate from './details/create-voucher'
import DialogEdit from './details/edit-voucher'

export default {
  name: 'ListVoucher',

  components: {
    DialogCreate,
    DialogEdit
  },

  data () {
    return {
      headers: [
        {
          text: 'Voucher Code',
          align: 'left',
          value: 'code'
        },
        {
          text: 'Date Created',
          align: 'left',
          value: 'fcreated_time'
        },
        {
          text: 'Voucher Title',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Type',
          align: 'left',
          value: 'voucher_type_desc'
        },
        {
          text: 'Package',
          align: 'left',
          value: 'subscription_id_desc'
        },
        {
          text: 'Business',
          align: 'left',
          value: 'new_account_desc'
        },
        {
          text: 'Expiry Date',
          align: 'left',
          value: 'fend_date'
        },
        {
          text: 'Qty',
          align: 'left',
          value: 'redeemable_all_com'
        }
      ],
      search: '',
      data: [],
      listLoading: true,
      transition: 'scale-transition',
      page: 0,
      total_page: 0,
      showDialogCreate: false,
      showDialogEdit: false,
      allTypes: ['Upgrade', 'Extend', 'Downgrade'],
      allPackages: [
        { id: 1, name: 'Free' },
        { id: 2, name: 'Basic' },
        { id: 3, name: 'Pro' }
      ],
      paramsQuery: {
        page: 1,
        per_page: 10
      },
      voucherID: ''
    }
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    }
  },

  mounted () {
    this.getData()
  },

  methods: {
    getData () {
      this.listLoading = true
      fetchSubscriptionVoucher(this.paramsQuery).then((response) => {
        this.data = response.data.data
        this.total_page = response.data.meta.last_page
        this.page = response.data.meta.current_page
        this.listLoading = false
      }).catch((error) => {
        console.log(error)
      })
    },
    getNext () {
      this.getData(this.page)
    },
    changePage (item) {
      this.paramsQuery = {
        page: item,
        per_page: 10,
        sort_type: 'desc',
        sort_column: 'id'
      }
      this.getData()
    },
    handleExport () {
      exportFile().then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]))
        var fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'file.xlsx')
        document.body.appendChild(fileLink)
        fileLink.click()
      }).catch((error) => {
        console.log(error)
      })
    },
    handleFilterType (e) {
      this.paramsQuery = {
        type: e
      }
      this.getData()
    },
    handleFilterPackage (e) {
      this.paramsQuery = {
        package: e
      }
      this.getData()
    },
    handleSearch (e) {
      this.paramsQuery = {
        search: e
      }
      this.getData()
    },
    handleAddVoucher () {
      this.showDialogCreate = true
      this.voucherID = ''
    },
    handleEdit (e) {
      this.voucherID = e.id.toString()
      this.showDialogEdit = true
    }
  }
}
</script>
