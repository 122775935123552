<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please wait...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    showDialog (val) {
      if (!val) return

      setTimeout(() => (this.$emit('closeLoading')), 400)
    }
  }
}
</script>
