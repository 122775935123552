<template>
  <div>
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="70%">
      <v-card class="pb-5">
        <v-toolbar color="elevation-0">
          <v-toolbar-title >
            <span
              v-if="!vID"
              class="ml-4 font-weight-bold">
              Add Voucher
            </span>
            <span
              v-else
              class="ml-4 font-weight-bold">
              Edit Voucher
            </span>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="mx-6 mt-6">
          <v-card
            id="custom-scroll"
            style="height: 400px; overflow-y: scroll;">
            <v-row
              class="mx-1">
              <v-col>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-row dense>
                    <v-col cols="12" dense>
                      <div class="d-flex flex-no-wrap justify-space-between">
                        <span>Voucher Type</span>
                      </div>
                      <v-autocomplete
                        v-model="dataVoucher.voucher_type"
                        :items="VoucherTypes"
                        item-text="name"
                        item-value="id"
                        outlined
                        dense
                      />
                    </v-col>
                    <v-col :cols="height" dense>
                      <v-row dense>
                        <v-col cols="12" sm="8" md="8">
                          <div class="d-flex flex-no-wrap justify-space-between mb-1">
                            <span>Title</span>
                          </div>
                          <v-text-field
                            v-model="dataVoucher.name"
                            outlined
                            dense
                            placeholder="Ex: Bonus Mantappu"
                          />
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <div class="d-flex flex-no-wrap justify-space-between mb-1">
                            <span>Code</span>
                          </div>
                          <v-text-field
                            v-model="dataVoucher.code"
                            outlined
                            dense
                            placeholder="Ex: 1234052"
                          />
                        </v-col>
                        <v-col
                        v-if="dataVoucher.voucher_type !== 3"
                        cols="12" sm="4" md="4">
                          <div class="d-flex flex-nowrap justify-space-between mb-1">
                            <span>Discount Type</span>
                          </div>
                          <v-autocomplete
                            v-model="dataVoucher.discount_type"
                            :items="discountType"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            class="ma-0"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="5"
                          md="5">
                          <!-- Currency -->
                          <v-autocomplete
                            v-if="dataVoucher.discount_type === 1 && dataVoucher.voucher_type !== 3"
                            v-model="dataVoucher.currency_id"
                            :items="currency"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            class="mt-7"
                          />
                          <!-- Month/Day -->
                          <div
                            v-if="dataVoucher.voucher_type === 3"
                            class="d-flex flex-nowrap justify-space-between">
                            <span>Trial Period</span>
                          </div>
                          <v-autocomplete
                            v-if="dataVoucher.discount_type === 3 || dataVoucher.voucher_type === 3"
                            v-model="dataVoucher.period"
                            :items="duration"
                            item-text="name"
                            item-value="id"
                            outlined
                            dense
                            :class="[ dataVoucher.voucher_type === 3 ? 'mt-1' : 'mt-7' ]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="3"
                          md="3">
                          <!-- Money-->
                          <v-text-field
                            v-if="dataVoucher.discount_type === 1 && dataVoucher.voucher_type !== 3"
                            v-model="dataVoucher.discount_value"
                            placeholder="Ex. 50000"
                            type="number"
                            outlined
                            dense
                            class="mt-7"
                          />
                          <!-- Percent -->
                          <v-text-field
                            v-if="dataVoucher.discount_type === 2"
                            v-model="dataVoucher.discount"
                            placeholder="30"
                            suffix="%"
                            type="number"
                            outlined
                            dense
                            class="mt-7"
                          />
                          <!-- Range -->
                          <v-text-field
                            v-if="dataVoucher.discount_type === 3 || dataVoucher.voucher_type === 3"
                            v-model="dataVoucher.num_of_period"
                            type="number"
                            outlined
                            dense
                            class="mt-7"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <div class="d-flex flex-no-wrap justify-space-between">
                            <span>Quantity</span>
                            <v-switch
                              v-model="dataVoucher.use_redeemable_all_com"
                              :hide-details="true"
                              :false-value="0"
                              :true-value="1"
                              dense
                              color="#00D79E"
                              class="ma-0"
                              @change="handleQuantity"
                            />
                          </div>
                          <v-text-field
                            v-model="dataVoucher.redeemable_all_com"
                            :disabled="vQuantity"
                            :filled="vQuantity"
                            outlined
                            dense
                            placeholder="0"
                            type="number"
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <div class="d-flex flex-no-wrap justify-space-between">
                            <span>Business Usage Limit</span>
                            <v-switch
                              v-model="dataVoucher.use_redeemable_per_com"
                              :hide-details="true"
                              :false-value="0"
                              :true-value="1"
                              dense
                              color="#00D79E"
                              class="ma-0"
                              @change="handleBusinessUsageLimit"
                            />
                          </div>
                          <v-text-field
                            v-model="dataVoucher.redeemable_per_com"
                            :disabled="vBusinessUsageLimit"
                            :filled="vBusinessUsageLimit"
                            outlined
                            dense
                            placeholder="100"
                            type="number"
                          />
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <div class="d-flex flex-no-wrap justify-space-between">
                            <span>Expiry Date</span>
                            <v-switch
                              v-model="dataVoucher.use_end_date"
                              :hide-details="true"
                              :false-value="0"
                              :true-value="1"
                              dense
                              color="#00D79E"
                              class="ma-0"
                              @change="handleExpiryDate"
                            />
                          </div>
                          <v-menu
                            v-model="fromDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                readonly
                                :value="dataVoucher.end_date"
                                :disabled="vExpiryDate"
                                :filled="vExpiryDate"
                                v-on="on"
                                outlined
                                dense
                                append-icon="mdi-calendar-range"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              locale="en-in"
                              v-model="dataVoucher.fromDateVal"
                              no-title
                              @input="fromDateMenu = false"
                              @change="handleDate"
                              :min="minDate"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="8" md="8">
                          <v-btn
                            v-for="(item, key) in 5"
                            :key="key"
                            outlined
                            small
                            color="#BEC2C4"
                            class="mt-8 mr-1 text-capitalize"
                            @click="handelEasePicker(item)">
                            {{ item + 'mo'}}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-divider class="hidden-sm-only hidden-xs-only mx-6" :vertical="true" />
                    <v-col>
                      <v-row dense>
                        <v-col cols="12" sm="12" md="12">
                          <div class="d-flex justify-space-between">
                            <span>Region</span>
                            <v-switch
                              v-model="dataVoucher.use_country_id"
                              :hide-details="true"
                              :false-value="0"
                              :true-value="1"
                              dense
                              color="#00D79E"
                              class="ma-0"
                              @change="handleRegion"
                            />
                          </div>
                          <v-autocomplete
                            v-model="dataVoucher.country_id"
                            :items="country"
                            :disabled="vRegion"
                            :filled="vRegion"
                            item-text="name"
                            item-value="id"
                            placeholder="Indonesia"
                            outlined
                            dense
                          />
                        </v-col>
                        <v-col
                          v-if="dataVoucher.voucher_type === 1"
                          cols="12" sm="12" md="12">
                          <div class="d-flex justify-space-between">
                            <span>
                              Origin Package
                            </span>
                            <v-switch
                              v-model="dataVoucher.use_origin_subscription_id"
                              :hide-details="true"
                              :false-value="0"
                              :true-value="1"
                              dense
                              color="#00D79E"
                              class="ma-0"
                              @change="handleOriginalPackage"
                            />
                          </div>
                          <v-autocomplete
                            v-model="dataVoucher.origin_subscription_id"
                            :items="originalPackage"
                            :disabled="vOriginalPackage"
                            :filled="vOriginalPackage"
                            item-text="name"
                            item-value="id"
                            placeholder="Basic"
                            outlined
                            dense
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <div class="d-flex flex-no-wrap justify-space-between">
                            <span v-if="dataVoucher.voucher_type === 2">Package</span>
                            <span v-else-if="dataVoucher.voucher_type === 3">Package Destination</span>
                            <span v-else>Upgrade Package</span>
                            <v-switch
                              v-model="dataVoucher.use_subscription_id"
                              :hide-details="true"
                              :false-value="0"
                              :true-value="1"
                              dense
                              color="#00D79E"
                              class="ma-0"
                              @change="handleUpgradePackage"
                            />
                          </div>
                          <v-autocomplete
                            v-model="dataVoucher.subscription_id"
                            :items="upgradePackage"
                            :disabled="cUpgradePackage"
                            :filled="cUpgradePackage"
                            item-text="name"
                            item-value="id"
                            placeholder="Pro"
                            outlined
                            dense
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card>
          <div
            class="text-right mt-9"
            style="width: 100%">
            <!-- <v-spacer></v-spacer> -->
            <v-btn
              v-if="vID"
              outlined
              color="#F5295A"
              class="mr-1 text-capitalize"
              @click="handleDelete">
              Delete Voucher
            </v-btn>
            <v-btn
              depressed
              color="#00D79E"
              class="text-capitalize white--text"
              @click="handleSelectBusiness">
              Next
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- <voucher-preview
      :showDialog="dialodVoucherPreview"
      :dataVoucher="dataVoucher"
      :vID="vID"
      @vRefreshData="handleDeleteRefreshData"
      @close="dialodVoucherPreview = false"
    /> -->
    <business-selection
      :showDialog="dialogBusiness"
      :dataVoucher="dataVoucher"
      @vRefreshData="handleDeleteRefreshData"
      @closeBusiness="dialogBusiness = false"
    />
    <delete-confirmation
      :showDialog="deleteConfirmation"
      :data="dataVoucher"
      @dRefreshData="handleDeleteRefreshData"
      @colseDeleteConfirmation="deleteConfirmation = false"
    />
    <loading
      :showDialog="loadingView"
      @closeLoading="loadingView = false"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { listCountry, currencyAll } from '@/api/global'
// import VoucherPreview from './voucher-preview'
import BusinessSelection from './choice-business'
import DeleteConfirmation from './delete-confirmation.vue'
import Loading from './loading.vue'
export default {
  components: {
    // VoucherPreview,
    BusinessSelection,
    DeleteConfirmation,
    Loading
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    vID: {
      type: String
    }
  },

  data () {
    return {
      dataVoucher: {
        fromDateVal: null,
        discount_type: 1
      },
      valid: true,
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 10) || 'Name must be less than 10 characters'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      select: null,
      VoucherTypes: [
        { id: 1, name: 'upgrade' },
        { id: 2, name: 'extend' },
        { id: 3, name: 'free trial' }
      ],
      originalPackage: [
        { id: 1, name: 'Free' },
        { id: 2, name: 'Basic' },
        { id: 3, name: 'Pro' }
      ],
      discountType: [
        { id: 1, name: 'Amount' },
        { id: 2, name: 'Discount' },
        { id: 3, name: 'Free Discount' }
      ],
      upgradePackage: [
        { id: 1, name: 'Free' },
        { id: 2, name: 'Basic' },
        { id: 3, name: 'Pro' }
      ],
      business: [
        { id: '1', name: 'All Business' },
        { id: '2', name: 'New Business Only' }
      ],
      currency: [],
      duration: [
        { id: 1, name: 'Month' },
        { id: 2, name: 'Day' }
      ],
      country: [],
      checkbox: false,
      fromDateMenu: false,
      fromDateVal: null,
      minDate: '2020-01-05',
      maxDate: '2019-08-30',
      dialodVoucherPreview: false,
      vOriginalPackage: true,
      vQuantity: true,
      vBusinessUsageLimit: true,
      vExpiryDate: true,
      vUpgradePackage: true,
      deleteConfirmation: false,
      loadingView: false,
      dialogBusiness: false,
      vRegion: true
    }
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    },
    fromDateDisp () {
      if (this.vID) {
        return this.dataVoucher.fend_date
      } else {
        return this.dataVoucher.fromDateVal
      }
    },
    cUpgradePackage () {
      if (this.vUpgradePackage === false) {
        return false
      } else {
        return true
      }
    },
    height () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 12
        case 'sm': return 12
        case 'md': return 8
        case 'lg': return 8
        case 'xl': return 8
        default: return 8
      }
    }
  },

  watch: {
    showDialog: {
      immediate: true,
      handler (payload) {
        if (payload) {
          this.getData()
        }
      }
    }
  },

  methods: {
    getData () {
      listCountry().then((response) => {
        this.country = response.data.data
      })
      currencyAll().then((response) => {
        this.currency = response.data.data
      })
    },
    closeDialog () {
      this.$emit('close', false)
      // this.reset()
      // if (this.vID) {
      //   this.dataVoucher = {}
      // }
    },
    handleDate () {
      this.dataVoucher.end_date = this.dataVoucher.fromDateVal
    },
    async handleView () {
      this.closeDialog()
      this.loadingView = true
      // await setTimeout(() => (this.loadingView = true), 4000)
      setTimeout(() => (this.dialodVoucherPreview = true), 500)
      // this.dialodVoucherPreview = true
    },
    handleRegion (e) {
      if (e === 1) {
        this.vRegion = false
      } else {
        this.vRegion = true
      }
    },
    handleOriginalPackage (e) {
      if (e === 1) {
        this.vOriginalPackage = false
      } else {
        this.vOriginalPackage = true
      }
    },
    handleQuantity (e) {
      if (e === 1) {
        this.vQuantity = false
      } else {
        this.vQuantity = true
      }
    },
    handleBusinessUsageLimit (e) {
      if (e === 1) {
        this.vBusinessUsageLimit = false
      } else {
        this.vBusinessUsageLimit = true
      }
    },
    handleExpiryDate (e) {
      if (e === 1) {
        this.vExpiryDate = false
      } else {
        this.vExpiryDate = true
      }
    },
    handleUpgradePackage (e) {
      if (e === 1) {
        this.vUpgradePackage = false
      } else {
        this.vUpgradePackage = true
      }
    },
    handleDelete () {
      this.$emit('close')
      this.deleteConfirmation = true
    },
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    handleDeleteRefreshData () {
      this.$emit('refreshData')
    },
    handelEasePicker (value) {
      var date = moment().add(value, 'months').format('YYYY-MM-DD')
      this.dataVoucher.end_date = date
      this.dataVoucher.fromDateVal = date
    },
    handleSelectBusiness () {
      this.$emit('close')
      this.dialogBusiness = true
    }
  }
}
</script>
