<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      width="400"
    >
      <v-card>
        <v-card-title class="headline">
        </v-card-title>
        <v-card-text>
          <div class="text-center">
            <v-icon color="#00D79E" size="100">
              mdi-checkbox-marked-circle-outline
            </v-icon>
          </div>
          <div
            v-if="!data"
            class="text-center font-weight-black">
            Voucher has been Added
          </div>
          <div v-else class="text-center font-weight-black">
            Voucher has been Edited
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="#00D79E"
            class="mx-auto text-capitalize white--text"
            depressed
            @click="closeDialog"
          >
            Go to Voucher List
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    data: {
      type: String
    }
  },

  methods: {
    closeDialog () {
      this.$emit('closeSuccess')
      this.$emit('sRefreshData')
    }
  }
}
</script>
